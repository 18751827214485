<template>
  <div class="definition">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'DefinitionIndex'
}
</script>
